import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ControlDirection, DownloadService, IntradayControlDirection, Money, PaginatedResponse, TimeSlot, Volume } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { isEmpty, pickBy } from 'lodash-es';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceResultsService {
  uri = '/api/v1/invoice-results';

  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  get(
    pageIndex?: number,
    pageSize?: number,
    searchTerm?: string,
    sort?: string,
    searchCategory?: string,
    period?: TimeSlot
  ): Observable<PaginatedResponse<InvoiceResult>> {
    const filter: any = {};

    if (searchTerm && !isEmpty(searchTerm) && searchCategory) {
      filter[searchCategory] = searchTerm;
    }

    return this.http.get<PaginatedResponse<InvoiceResult>>(this.uri, {
      params: pickBy(
        {
          page: pageIndex,
          pageSize: pageSize,
          sort,
          ...TimeSlot.toPeriodFilter(period),
          filter: isEmpty(filter) ? null : JSON.stringify(filter)
        },
        (a) => !!a || a === 0
      ) as any
    });
  }

  recalculate(period: TimeSlot): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.uri, {
      ...TimeSlot.toPeriodFilter(period)
    });
  }

  downloadInvoiceResults(period: TimeSlot, searchTerm?: string, searchCategory?: string, sort?: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({ Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filter: any = {};
    if (searchTerm && !isEmpty(searchTerm) && searchCategory) {
      filter[searchCategory] = searchTerm;
    }

    return this.downloadService.download(
      this.http.get<Blob>(this.uri, {
        headers,
        params: pickBy(
          {
            sort,
            filter: isEmpty(filter) ? null : JSON.stringify(filter),
            ...TimeSlot.toPeriodFilter(period)
          },
          (a) => !!a
        ) as any,
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }

  publish(period: TimeSlot): Observable<PublishResult> {
    return this.http.post<PublishResult>(`${this.uri}/publish`, {
      startDate: moment(period.startDateTime).format('YYYY-MM-DD'),
      endDate: moment(period.toDateTime).subtract(1, 'day').format('YYYY-MM-DD')
    });
  }
}

export class PublishResult {
  message: string;
}

export enum InvoiceResultStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  CHANGED = 'CHANGED'
}

export class InvoiceResult {
  customerName: string;
  gridPointId: string;
  gridPointEan: string;
  period: TimeSlot;
  resultType: string;
  volume: Volume;
  volumeDirection: IntradayControlDirection;
  amount: Money;
  status: InvoiceResultStatus;
  reference: string;
  sentAmount?: Money;
  sentVolume?: Volume;
}
