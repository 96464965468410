import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ControlDirection, DownloadService, Money, PaginatedResponse, TimeSlot, Volume } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { isEmpty, pickBy } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ImbalanceCorrectionService {
  uri = '/api/v1/imbalance-correction';

  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  get(
    pageIndex?: number,
    pageSize?: number,
    searchTerm?: string,
    sort?: string,
    searchCategory?: string,
    period?: TimeSlot
  ): Observable<PaginatedResponse<ImbalanceCorrection>> {
    const filter: any = {};

    if (searchTerm && !isEmpty(searchTerm) && searchCategory) {
      filter[searchCategory] = searchTerm;
    }

    return this.http.get<PaginatedResponse<ImbalanceCorrection>>(this.uri, {
      params: pickBy(
        {
          page: pageIndex,
          pageSize,
          sort,
          ...TimeSlot.toPeriodFilter(period),
          filter: isEmpty(filter) ? null : JSON.stringify(filter)
        },
        (a) => !!a || a === 0
      ) as any
    });
  }

  downloadImbalanceCorrection(
    period: TimeSlot,
    searchTerm?: string,
    searchCategory?: string,
    sort?: string
  ): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({ Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filter: any = {};
    if (searchTerm && !isEmpty(searchTerm) && searchCategory) {
      filter[searchCategory] = searchTerm;
    }

    return this.downloadService.download(
      this.http.get<Blob>(this.uri, {
        headers,
        params: pickBy(
          {
            sort,
            filter: isEmpty(filter) ? null : JSON.stringify(filter),
            ...TimeSlot.toPeriodFilter(period)
          },
          (a) => !!a
        ) as any,
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }

  recalculate(period: TimeSlot): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.uri, {
      ...TimeSlot.toPeriodFilter(period)
    });
  }
}

export class ImbalanceCorrection {
  customerName: string;
  gridPointId: string;
  gridPointEan: string;
  period: TimeSlot;
  correctionVolume: Volume;
  correctionDirection: ControlDirection;
  correctionAmount: Money;
}
