import { AppInjector, FrontendPaginationHelper, OnDestroyProvider, PaginatedDataSource } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, ReplaySubject } from 'rxjs';
import { IntradayDealReporting } from '../../shared/intraday-deal-reporting/intraday-deal-reporting';
import { IntradayDealReportingFacade } from '../../../store/intraday-deal-reporting/intraday-deal-reporting.facade';
import { get } from 'lodash-es';

export class IntradayDealReportingDataDatasource extends PaginatedDataSource<IntradayDealReporting> {
  loadDataSubject = new ReplaySubject(1);

  paginationHelper = new FrontendPaginationHelper<IntradayDealReporting>(
    (data, sortHeaderId) => {
      if (sortHeaderId === 'volume') {
        return get(data, sortHeaderId).value;
      }
      if (sortHeaderId === 'customerPrice' || sortHeaderId === 'sourcingPrice') {
        return get(data, sortHeaderId).money.amount;
      }
      if (sortHeaderId === 'customerAmount' || sortHeaderId === 'sourcingAmount') {
        return get(data, sortHeaderId).amount;
      }
      return get(data, sortHeaderId);
    },
    {
      keys: ['customerReference', 'sourcingReference'],
      threshold: 0.3
    }
  );
  facade = AppInjector.get(IntradayDealReportingFacade);

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    onDestroyProvider.onDestroy$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.ngOnDestroy());

    combineLatest([this.facade.data$, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([newResult, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        this.paginationHelper.updateData(newResult);

        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, searchTerm, sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.filteredData.length);
      });
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.loadDataSubject.next({
      pageIndex,
      pageSize,
      searchTerm,
      sort
    });
  }
}
