import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DataSourceFilterProvider, TimeSlot } from 'flex-app-shared';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})
export class DateRangeFilterComponent implements OnChanges {
  currentTimeSlot = TimeSlot.getMonthPeriod(moment());

  wholeMonthOnly: boolean = true;

  @Input()
  showWholeMonthOnlyToggle: boolean = true;

  @Input() startView: string = 'multi-year';

  @Input() period: TimeSlot;

  @Output() periodChanged = new EventEmitter<TimeSlot>();

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>): void {
    this.currentTimeSlot = TimeSlot.getMonthPeriod(normalizedMonth);

    this.updateValues();

    datepicker.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.period) {
      // TODO check for month duration, and set mode accordingly
      this.currentTimeSlot = this.period;

      // Set wholeMonthOnly based on provided period
      this.wholeMonthOnly = isEqual(TimeSlot.getMonthPeriod(this.period.startDateTime), this.period);

      this.updateValues();
    }
  }

  updateValues(): void {
    const period = !this.wholeMonthOnly ? this.currentTimeSlot : TimeSlot.getMonthPeriod(moment(this.currentTimeSlot.startDateTime));

    this.period = period;
    this.periodChanged.emit(period);
  }
}

export class TimeSlotFilterProvider extends DataSourceFilterProvider<DateRangeFilterComponent, TimeSlot> {
  protected getChangedObservable(item: DateRangeFilterComponent): Observable<TimeSlot> {
    return item.periodChanged.asObservable();
  }
}
