<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <form [formGroup]="form">
        <div class="ph-flex-mat-form-field-group-inline compact">
          <mat-form-field phFlexDateAsMonth class="ph-flex-mat-form-field-slim">
            <mat-label>Month</mat-label>
            <input matInput [matDatepicker]="selectedDateDatepicker" formControlName="selectedDate" />
            <mat-datepicker-toggle matSuffix [for]="selectedDateDatepicker"></mat-datepicker-toggle>
            <mat-datepicker
              #selectedDateDatepicker
              startView="year"
              (monthSelected)="chosenDateHandler($event, selectedDateDatepicker)"
              panelClass="datepicker-disable-control-button datepicker-month"
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
      <ph-flex-table-filter placeholder="Search"></ph-flex-table-filter>
      <div class="minimize">
        <ph-flex-save-button
          [pending]="facade.busyDownloading$ | async"
          (clickChanged)="facade.downloadData()"
          [disabled]="!(facade.canDownload$ | async)"
          color="secondary"
          type="stroked"
        >
          <ph-flex-vertical-center>
            <mat-icon svgIcon="ic-old:download"></mat-icon>
            <span>Download invoice data</span>
          </ph-flex-vertical-center>
        </ph-flex-save-button>
      </div>
    </mat-toolbar>
    <ph-flex-loading-context [isLoading]="facade.busy$ | async">
      <div class="overflow-table">
        <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
          <ng-container matColumnDef="customerReference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer reference</th>
            <td mat-cell *matCellDef="let row">{{ row.customerReference }}</td>
          </ng-container>
          <ng-container matColumnDef="sourcingReference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sourcing reference</th>
            <td mat-cell *matCellDef="let row">{{ row.sourcingReference }}</td>
          </ng-container>
          <ng-container matColumnDef="broker">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Broker</th>
            <td mat-cell *matCellDef="let row">{{ row.broker }}</td>
          </ng-container>
          <ng-container matColumnDef="direction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="row.direction">
              <ng-container *ngSwitchCase="IntradayControlDirection.CONSUMPTION">Consumption</ng-container>
              <ng-container *ngSwitchCase="IntradayControlDirection.PRODUCTION">Production</ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deal volume [MWh]</th>
            <td mat-cell *matCellDef="let row">{{ row.volume | capacityNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="customerPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer price [€/MWh]</th>
            <td mat-cell *matCellDef="let row">{{ row.customerPrice.money | money }}</td>
          </ng-container>
          <ng-container matColumnDef="customerAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer amount [€]</th>
            <td mat-cell *matCellDef="let row">{{ row.customerAmount | money }}</td>
          </ng-container>
          <ng-container matColumnDef="sourcingPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sourcing price [€/MWh]</th>
            <td mat-cell *matCellDef="let row">{{ row.sourcingPrice.money | money }}</td>
          </ng-container>
          <ng-container matColumnDef="sourcingAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sourcing amount [€]</th>
            <td mat-cell *matCellDef="let row">{{ row.sourcingAmount | money }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
    </ph-flex-loading-context>
  </mat-card-content>
</mat-card>
