import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ImbalanceCorrectionDatasource } from './imbalance-correction.datasource';
import { ImbalanceCorrectionService } from '../../shared/imbalance-correction/imbalance-correction.service';
import { IntradayControlDirection, TableFilterComponent, TimeSlot } from 'flex-app-shared';
import { DateRangeFilterComponent } from '../date-range-filter/date-range-filter.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-imbalance-correction',
  templateUrl: './imbalance-correction.component.html',
  styleUrls: ['./imbalance-correction.component.scss']
})
export class ImbalanceCorrectionComponent implements AfterViewInit {
  displayedColumns = [
    'customerName',
    'gridPointEan',
    'gridPointDescription',
    'period',
    'correctionVolume',
    'correctionDirection',
    'correctionAmount'
  ];
  dataSource = new ImbalanceCorrectionDatasource(this.imbalanceCorrectionService);
  IntradayControlDirection = IntradayControlDirection;

  @ViewChild(DateRangeFilterComponent) dateRangeFilter: DateRangeFilterComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(TableFilterComponent) tableFilter: TableFilterComponent;
  @ViewChild('filterCategory') filterCategory: MatSelect;

  TimeSlot = TimeSlot;

  currentMonthPeriod = TimeSlot.getMonthPeriod(moment());

  constructor(
    private snackBar: MatSnackBar,
    private imbalanceCorrectionService: ImbalanceCorrectionService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.periodFilter = this.dateRangeFilter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.tableFilter;
    this.dataSource.filterCategory = this.filterCategory;
  }

  recalculate(): void {
    this.imbalanceCorrectionService.recalculate(this.dateRangeFilter.period).subscribe(() => {
      this.snackBar.open('Imbalance correction recalculation started', 'ok', {
        duration: 3000
      });
    });
  }
}
