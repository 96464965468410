import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FlexNgXsFormSync, IntradayControlDirection, MixinBase, OnDestroyMixin, TableFilterComponent } from 'flex-app-shared';
import { IntradayDealReportingFacade } from '../../../store/intraday-deal-reporting/intraday-deal-reporting.facade';
import { IntradayDealReportingDataDatasource } from './deal-reporting.datasource';

@Component({
  selector: 'app-deal-reporting',
  templateUrl: './deal-reporting.component.html',
  styleUrls: ['./deal-reporting.component.scss']
})
export class DealReportingComponent extends OnDestroyMixin(MixinBase) implements AfterViewInit {
  displayedColumns = [
    'customerReference',
    'sourcingReference',
    'broker',
    'direction',
    'volume',
    'customerPrice',
    'customerAmount',
    'sourcingPrice',
    'sourcingAmount'
  ];
  IntradayControlDirection = IntradayControlDirection;

  @FlexNgXsFormSync('intradayDealReporting.filter', { initialSyncOnlyTruthyFormValues: true })
  form = this.builder.group({
    selectedDate: [new Date(), [Validators.required]]
  });

  dataSource = new IntradayDealReportingDataDatasource(this);

  @ViewChild(TableFilterComponent)
  private readonly tableFilter: TableFilterComponent;

  @ViewChild(MatPaginator, { static: true })
  private readonly paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  private readonly sort: MatSort;

  constructor(private builder: UntypedFormBuilder, public facade: IntradayDealReportingFacade) {
    super();
  }

  chosenDateHandler(selectedMoment: Date, datepicker: MatDatepicker<any>): void {
    this.form.get('selectedDate').setValue(selectedMoment);
    datepicker.close();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.tableFilter;
  }
}
