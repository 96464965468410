<div class="flex-row">
  <mat-card class="col-12">
    <mat-card-content>
      <mat-toolbar class="filter-bar">
        <mat-form-field>
          <mat-label>Search category</mat-label>
          <mat-select value="customerName" #filterCategory>
            <mat-option [value]="'customerName'">Customer</mat-option>
            <mat-option [value]="'gridPointEan'">EAN</mat-option>
            <mat-option [value]="'gridPointDescription'">Grid point</mat-option>
          </mat-select>
        </mat-form-field>
        <ph-flex-table-filter label="Search"></ph-flex-table-filter>
        <div class="minimize">
          <button mat-stroked-button (click)="dataSource.download()">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:download"></mat-icon>
              <span>Download data</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="primary" (click)="recalculate()">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:calc"></mat-icon>
              <span>Recalculate</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </mat-toolbar>
      <mat-toolbar class="filter-bar">
        <app-date-range-picker [period]="currentMonthPeriod" startView="year"></app-date-range-picker>
      </mat-toolbar>

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
          <td mat-cell *matCellDef="let row">{{ row.customerName }}</td>
        </ng-container>
        <ng-container matColumnDef="gridPointEan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
          <td mat-cell *matCellDef="let row">{{ row.gridPointEan }}</td>
        </ng-container>
        <ng-container matColumnDef="gridPointDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Grid point</th>
          <td mat-cell *matCellDef="let row">{{ row.gridPointDescription }}</td>
        </ng-container>
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
          <td mat-cell *matCellDef="let row">
            {{ row.period.startDateTime | date: 'shortDate' }} | {{ row.period.startDateTime | date: 'shortTime' }}-{{
              row.period.toDateTime | date: 'shortTime'
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="correctionVolume">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Correction volume [MWh]</th>
          <td mat-cell *matCellDef="let row">{{ row.correctionVolume?.value | number: '0.3-3' }}</td>
        </ng-container>
        <ng-container matColumnDef="correctionDirection">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Correction direction</th>
          <td mat-cell *matCellDef="let row" [ngSwitch]="row.correctionDirection">
            <ng-container *ngSwitchCase="IntradayControlDirection.CONSUMPTION">Consumption</ng-container>
            <ng-container *ngSwitchCase="IntradayControlDirection.PRODUCTION">Production</ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="correctionAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Correction amount</th>
          <td mat-cell *matCellDef="let row">{{ row.correctionAmount | money }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
