import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IntradayDealReportingState } from './intraday-deal-reporting.state';
import { IntradayDealReportingDownloadDataCommand } from './intraday-deal-reporting.actions';

@Injectable({
  providedIn: 'root'
})
export class IntradayDealReportingFacade {
  constructor(private store: Store) {}

  data$ = this.store.select(IntradayDealReportingState.data);
  busy$ = this.store.select(IntradayDealReportingState.busy);
  busyDownloading$ = this.store.select(IntradayDealReportingState.busyDownloading);
  canDownload$ = this.store.select(IntradayDealReportingState.canDownload);

  downloadData(): void {
    this.store.dispatch(new IntradayDealReportingDownloadDataCommand());
  }
}
