import { RouterModule, Routes } from '@angular/router';
import { ImbalanceCorrectionComponent } from './imbalance-correction/imbalance-correction.component';
import { NgModule } from '@angular/core';
import { InvoiceResultsComponent } from './invoice-results/invoice-results.component';
import { DealReportingComponent } from './deal-reporting-component/deal-reporting.component';

const routes: Routes = [
  {
    path: 'imbalance-correction',
    component: ImbalanceCorrectionComponent
  },
  {
    path: 'invoice-results',
    component: InvoiceResultsComponent
  },
  {
    path: 'deal-reporting',
    component: DealReportingComponent
  },
  {
    path: '**',
    redirectTo: 'imbalance-correction'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntradayRoutingModule {}
