import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { IntradayControlDirection, TableFilterComponent, TimeSlot } from 'flex-app-shared';
import moment from 'moment';
import { InvoiceResultsService, InvoiceResultStatus } from '../../shared/invoice-results/invoice-results.service';
import { DateRangeFilterComponent } from '../date-range-filter/date-range-filter.component';
import { InvoiceResultsDatasource } from './invoice-results.datasource';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-invoice-results',
  templateUrl: './invoice-results.component.html',
  styleUrls: ['./invoice-results.component.scss']
})
export class InvoiceResultsComponent implements AfterViewInit {
  displayedColumns = [
    'customerName',
    'gridPointEan',
    'gridPointDescription',
    'period',
    'resultType',
    'volume',
    'direction',
    'amount',
    'reference',
    'status'
  ];
  dataSource = new InvoiceResultsDatasource(this.invoiceResultsService);
  IntraDayControlDirection = IntradayControlDirection;
  InvoiceResultStatus = InvoiceResultStatus;

  @ViewChild(DateRangeFilterComponent) dateRangeFilter: DateRangeFilterComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(TableFilterComponent) tableFilter: TableFilterComponent;
  @ViewChild('filterCategory') filterCategory: MatSelect;

  TimeSlot = TimeSlot;
  currentMonthPeriod = TimeSlot.getMonthPeriod(moment());

  constructor(
    private snackBar: MatSnackBar,
    private invoiceResultsService: InvoiceResultsService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.periodFilter = this.dateRangeFilter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.tableFilter;
    this.dataSource.filterCategory = this.filterCategory;
  }

  recalculate(): void {
    this.invoiceResultsService.recalculate(this.dateRangeFilter.period).subscribe(() => {
      this.snackBar.open('Invoice results recalculation started', 'ok', {
        duration: 3000
      });
    });
  }

  publish(): void {
    this.snackBar.open('Starting sending invoice results to Infinity', 'ok', { duration: 5000 });
    this.dataSource.publish();
  }
}
