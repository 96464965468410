<mat-slide-toggle *ngIf="showWholeMonthOnlyToggle" [(ngModel)]="wholeMonthOnly" (ngModelChange)="updateValues()"
  >Months only
</mat-slide-toggle>
<mat-form-field class="whole-month" *ngIf="wholeMonthOnly" phFlexDateAsMonth>
  <mat-label>Filter month</mat-label>
  <input
    matInput
    [matDatepicker]="monthDatepicker"
    [ngModel]="currentTimeSlot.startDateTime"
    (dateInput)="updateValues()"
    (dateChange)="updateValues()"
  />
  <mat-datepicker-toggle matSuffix [for]="monthDatepicker"></mat-datepicker-toggle>
  <mat-datepicker
    #monthDatepicker
    [startView]="startView"
    (monthSelected)="chosenMonthHandler($event, monthDatepicker)"
    panelClass="month-datepicker"
  ></mat-datepicker>
</mat-form-field>
<ng-container *ngIf="!wholeMonthOnly">
  <mat-form-field>
    <mat-label>Start date</mat-label>
    <input matInput [matDatepicker]="fromDatePicker" [(ngModel)]="currentTimeSlot.startDateTime" (ngModelChange)="updateValues()" />
    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #fromDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>To date</mat-label>
    <input matInput [matDatepicker]="toDatePicker" [(ngModel)]="currentTimeSlot.toDateTime" (ngModelChange)="updateValues()" />
    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #toDatePicker></mat-datepicker>
  </mat-form-field>
</ng-container>
