import { IntradayDealReporting } from '../../app/shared/intraday-deal-reporting/intraday-deal-reporting';

export class UpdateIntradayDealReportingCommand {
  public static readonly type = '[Intra day deal reporting] Load data';

  constructor(public date: Date) {}
}

export class IntradayDealReportingUpdatedEvent {
  public static readonly type = '[Intra day deal reporting] Data updated';

  constructor(public data: IntradayDealReporting[]) {}
}

export class IntradayDealReportingDownloadDataCommand {
  public static readonly type = '[Intra day deal reporting] Download data for current date';
}
