import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImbalanceCorrectionComponent } from './imbalance-correction/imbalance-correction.component';
import { IntradayRoutingModule } from './intraday-routing.module';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FlexCoreModule, FlexLoadingContextModule, FlexSaveButtonModule, FlexTableFilterModule } from 'flex-app-shared';
import { MatButtonModule } from '@angular/material/button';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexDateFormattersModule } from 'flex-app-shared';
import { InvoiceResultsComponent } from './invoice-results/invoice-results.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DealReportingComponent } from './deal-reporting-component/deal-reporting.component';

@NgModule({
  declarations: [ImbalanceCorrectionComponent, DateRangeFilterComponent, InvoiceResultsComponent, DealReportingComponent],
  imports: [
    CommonModule,
    IntradayRoutingModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    FlexLoadingContextModule,
    FlexSaveButtonModule,
    FlexTableFilterModule,
    FlexCoreModule,
    MatButtonModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    FormsModule,
    MatTooltipModule,
    FlexDateFormattersModule
  ],
  exports: [DateRangeFilterComponent]
})
export class IntradayModule {}
