import { takeUntil, tap } from 'rxjs/operators';
import { asBatch, PaginatedDataSource, SelectFilterProvider } from 'flex-app-shared';
import { Subject } from 'rxjs';
import { DateRangeFilterComponent, TimeSlotFilterProvider } from '../date-range-filter/date-range-filter.component';
import { MatSelect } from '@angular/material/select';
import { InvoiceResult, InvoiceResultsService } from '../../shared/invoice-results/invoice-results.service';
import { CollectionViewer } from '@angular/cdk/collections';

export class InvoiceResultsDatasource extends PaginatedDataSource<InvoiceResult> {
  data$ = this.dataSubject.asObservable().pipe(
    tap((value) => (this.data = value)),
    asBatch({
      batchSize: 10,
      batchDelayMs: 20
    })
  );

  protected cancelRequestSubject = new Subject<void>();

  protected periodFilterProvider = new TimeSlotFilterProvider(this);
  protected categoryFilterProvider = new SelectFilterProvider(this);

  constructor(private service: InvoiceResultsService) {
    super();
    this.refreshDataObservables.push(this.periodFilterProvider.onChanges$, this.categoryFilterProvider.onChanges$);
  }

  set periodFilter(value: DateRangeFilterComponent) {
    this.periodFilterProvider.register(value);
  }

  set filterCategory(value: MatSelect) {
    this.categoryFilterProvider.register(value);
  }

  disconnect(collectionViewer: CollectionViewer): void {
    super.disconnect(collectionViewer);
    this.cancelRequestSubject.next();
    this.cancelRequestSubject.complete();
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.cancelRequestSubject.next();
    this.service
      .get(pageIndex, pageSize, searchTerm, sort, this.categoryFilterProvider?.item?.value, this.periodFilterProvider?.item?.period)
      .pipe(takeUntil(this.cancelRequestSubject))
      .subscribe((result) => {
        this.dataSubject.next(result.content);
        this.updatePaginatorFromPaginatedResponse(result);
      });
  }

  download(): void {
    this.service
      .downloadInvoiceResults(
        this.periodFilterProvider?.item?.period,
        this.tableFilterProvider?.item?.filterTerm,
        this.categoryFilterProvider?.item?.value,
        this.getSortString()
      )
      .subscribe(() => {
        // noop
      });
  }

  publish(): void {
    this.service.publish(this.periodFilterProvider?.item?.period).subscribe(() => {
      // noop
    });
    this.doLoadData(); // refresh to see the results if already available
  }
}
