import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { DownloadService, getRetryConfig } from 'flex-app-shared';
import { format } from 'date-fns';
import { IntradayDealReporting } from './intraday-deal-reporting';

@Injectable({
  providedIn: 'root'
})
export class IntradayDealReportingService {
  private endpoint = '/api/v1/intra-day-deal-reporting';

  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  getIntradayDealReporting(date: Date): Observable<IntradayDealReporting[]> {
    return this.http.get<IntradayDealReporting[]>(`${this.endpoint}/${format(date, 'yyyy/MM')}`).pipe(retry(getRetryConfig()));
  }

  downloadExcel(date: Date): any {
    return this.downloadService.downloadExcel(`${this.endpoint}/${format(date, 'yyyy/MM')}`);
  }
}
